window.addEventListener('load', () => {
  if (document.body.classList.contains('page--contact')) {
    const osmLayer = L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      maxZoom: 24,
      attribution: '© <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });

    const map = L.map('header_img', {scrollWheelZoom: false})
      .setView([53.238565, 6.592164], 18)
      .addLayer(osmLayer);
    const icon = L.icon({
      iconUrl: '/img/logo-only.svg',
      iconSize: [42, 42]
    })
    const marker = L.marker([53.238475, 6.592164], {icon: icon}).addTo(map);
  }
});
